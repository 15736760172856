var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('div',[_c('list-table',{attrs:{"records":_vm.resources,"columns":_vm.tableColumns,"total-records":_vm.totalRecords,"current-page-number":_vm.currentPage,"search-filter":_vm.searchFilter},on:{"update:totalRecords":function($event){_vm.totalRecords=$event},"update:total-records":function($event){_vm.totalRecords=$event},"update:currentPageNumber":function($event){_vm.currentPage=$event},"update:current-page-number":function($event){_vm.currentPage=$event},"update:searchFilter":function($event){_vm.searchFilter=$event},"update:search-filter":function($event){_vm.searchFilter=$event},"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('b-button',{attrs:{"variant":"outline"},on:{"click":row.toggleDetails}},[(row.detailsShowing)?_c('feather-icon',{attrs:{"icon":"ChevronUpIcon","size":"18"}}):_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"18"}})],1)]}},{key:"action_button",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","to":{ name: 'admin-new-resource' }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("New Resource")])])]},proxy:true},{key:"cell(resource_name)",fn:function(data){return [_c('div',{staticClass:"my-1"},[_c('p',[_vm._v(" "+_vm._s(data.item.resource_name)+" ")])])]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
              name: 'admin-update-resource',
              params: { id: data.item._id },
            }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Update")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deactivateResource(data.item._id, data.item)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50 text-danger"},[_vm._v("Deactivate")])],1)],1)]}},{key:"row-details",fn:function(row){return [_c('div',[_c('b-table',{ref:"refDataTable",staticClass:"position-relative rounded-lg",attrs:{"items":row.item.resource_actions,"fields":[
              { key: 'name', sortable: false, label: 'Resource Actions' },
              { key: 'access_level', sortable: false, label: 'Access Levels' },
              { key: 'actions' }
            ],"responsive":"","show-empty":"","primary-key":"_id","empty-text":"No matching records found","tbody-tr-class":"loan-list-tb-row","thead-tr-class":"text-muted rounded-lg ","thead-class":"bg-light rounded-lg"},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'admin-update-resource-action',
                  params: {
                    id: data.item._id,
                    resource_id: row.item.id
                  },
                }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Update")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteResourceAction(data.item._id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]}}],null,true)})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }