<template>
  <b-overlay :show="loading">
    <div>
      <list-table :records="resources"
                  :columns="tableColumns"
                  :total-records.sync="totalRecords"
                  :current-page-number.sync="currentPage"
                  :search-filter.sync="searchFilter"
                  @row-clicked="onRowClicked"
      >
        <template #cell(show_details)="row">
          <b-button variant="outline" @click="row.toggleDetails">
            <feather-icon v-if="row.detailsShowing" icon="ChevronUpIcon" size="18" />

            <feather-icon v-else icon="ChevronDownIcon" size="18" />
          </b-button>
        </template>

        <template #action_button>
          <b-button variant="primary" :to="{ name: 'admin-new-resource' }">
            <span class="text-nowrap">New Resource</span>
          </b-button>
        </template>

        <template #cell(resource_name)="data">
          <div class="my-1">
            <p>
              {{ data.item.resource_name }}
            </p>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item :to="{
                name: 'admin-update-resource',
                params: { id: data.item._id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Update</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deactivateResource(data.item._id, data.item)">
              <feather-icon class="text-danger" icon="TrashIcon" />
              <span class="align-middle ml-50 text-danger">Deactivate</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #row-details="row">
          <div>
            <b-table 
              ref="refDataTable" class="position-relative rounded-lg" 
              :items="row.item.resource_actions" :fields="[
                { key: 'name', sortable: false, label: 'Resource Actions' },
                { key: 'access_level', sortable: false, label: 'Access Levels' },
                { key: 'actions' }
              ]" 
              responsive 
              show-empty 
              primary-key="_id" 
              empty-text="No matching records found" 
              tbody-tr-class="loan-list-tb-row" 
              thead-tr-class="text-muted rounded-lg " 
              thead-class="bg-light rounded-lg"
            >
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item :to="{
                    name: 'admin-update-resource-action',
                    params: {
                      id: data.item._id,
                      resource_id: row.item.id
                    },
                  }"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Update</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteResourceAction(data.item._id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import {
  BOverlay,
  BButton,
  BDropdown,
  BDropdownGroup,
  BDropdownItem,
  BTable
} from "bootstrap-vue";
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  debounce,
  get
} from "lodash";

const watchHandler = {
  handler() {
    this.debounceFetchAllData();
  },
  immediate: false,
};

export default {
  name: "ListResources",
  components: {
    ListTable,
    BOverlay,
    BButton,
    BDropdownGroup,
    BTable,
    BDropdownItem,
    BDropdown
  },
  data() {
    return {
      loading: false,
      resources: [],
      resource_actions: [],
      searchFilter: null,
      tableColumns: [{
          key: 'show_details',
          _showDetails: true,
          label: "#"
        },
        {
          key: 'title',
          sortable: false,
          label: 'Title'
        },
        {
          key: 'resource_name',
          sortable: false,
          label: 'Resource'
        },
        {
          key: 'description',
          sortable: false,
          label: 'Description'
        },
        {
          key: 'is_a_service',
          sortable: false,
          label: 'Service Resource'
        },
        {
          key: 'is_active',
          sortable: false,
          label: 'Active '
        },
        {
          key: 'actions'
        }
      ],
      debounceFetchAllData: () => {},
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get() {
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
        this.debounceFetchAllData();
      },
      immediate: false,
    },
    currentPage: watchHandler,
    statusFilter: watchHandler,
    searchFilter: watchHandler,
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchAllData, 500);

    const {
      page = 1,
      search = "",
      limit = this.perPage
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.perPage = +limit;
  },
  methods: {
    onRowClicked(rowItem) {
      this.$router.push({
        name: "admin-update-resource",
        params: {
          id: rowItem._id
        },
      });
    },
    async fetchAllData() {
      try {
        this.loading = true;

        const {
          perPage: limit,
          currentPage: page,
          searchFilter: search,
        } = this;

        const query = {
          limit,
          page,
          search,
        };

        this.$router.push({
            query
          })
          .catch(() => {});

        const request = await useJwt.adminService.getResources(query);
        const {
          data,
          pagination
        } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.resources = data;
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async deactivateResource(resource_id, resource_payload) {
      try {
        this.loading = true;

        const deleteConfirmation = await this.$swal({
          title: 'Deactivate this resource?',
          text: "You are about to deactivate a resource. Continue?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!deleteConfirmation.value) {
          return;
        }

        const payload = {
          title: resource_payload.title,
          description: resource_payload.description,
          is_active: false
        }

        await useJwt.adminService.deactivateResource(resource_id, payload);
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteResourceAction(resource_action_id) {
      try {
        this.loading = true;

        const deleteConfirmation = await this.$swal({
          title: 'Delete this Resource Action?',
          text: "You are about to delete a resource action. Continue?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!deleteConfirmation.value) {
          return;
        }

        await useJwt.adminService.deleteResourceAction(resource_action_id);
        await this.fetchAllData();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            icon: 'EditIcon',
            variant: 'success',
            text: 'Resource Action Deleted Successfully'
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
.loan-list-tb-row {
  cursor: pointer !important;
  background-color: #EEF7FC;
}

.dark-layout {
  .loan-list-tb-row {
    background-color: #343d55;
  }
}
</style>
